<template>
  <b-modal
      id="modal-remove-report-item"
      ref="modal-remove-report-item"
      footer-class="flex-nowrap"
      no-close-on-esc
      no-close-on-backdrop
      centered
  >
    <template #modal-header>
      <h3>{{ $t('modals.removeItem.title') }}</h3>
    </template>
    <div class="delete-icon text-center px-2">
      <b-icon icon="exclamation-triangle-fill" variant="danger" class="mt-5 mb-4"></b-icon>
      <div v-if="item && item.name"><strong>{{ item.name }}</strong></div>
      <div class="mt-2 mb-4" v-html="$t('modals.removeItem.message')"></div>
    </div>
    <template #modal-footer>
      <b-button
        class="p-3 border-right-light-grey"
        block
        @click="cancel"
      >
        {{ $t('buttons.cancel') }}
      </b-button>
      <b-button
        variant="primary"
        class="p-3"
        block
        @click="removeItem"
      >
        {{ $t('buttons.ok') }}
      </b-button>
    </template>
  </b-modal>
</template>

<script>
export default {
  name: 'ModalDeleteItem',
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  mounted() {
    this.$refs['modal-remove-report-item'].show();
  },
  methods: {
    removeItem() {
      this.$emit('remove-item', this.item);
    },
    cancel() {
      this.$emit('cancel');
    },
  },
};
</script>

<style scoped lang="scss">
.delete-icon svg {
  font-size: 80px;
}
</style>
